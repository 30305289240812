import React from 'react'
import UiLink from 'components/ui/ui-link/UiLink'
import { useRouter } from 'next/router'
import { Description } from 'components/typography'
import { NextJsApiHelper } from 'helpers/nextJsApiHelper'
import { RouterPaths } from 'constants/paths/routerPaths'
import Profile from 'images/icons/profile-icn.svg'
import Exit from 'images/icons/exit-icn.svg'
import styles from './ProfileDropdown.module.scss'

const ProfileDropdown: React.FC = () => {
  const router = useRouter()
  const nextJsApiHelperInstance = new NextJsApiHelper()

  const onLogOut = async (): Promise<void> => {
    await nextJsApiHelperInstance.logOut()
    router.push(RouterPaths.SIGN_IN)
  }

  return (
    <div className={styles.container}>
      <UiLink className={styles.row} href={RouterPaths.MY_PROFILE}>
        <Profile />
        <Description className={styles.text}>My profile</Description>
      </UiLink>
      <div
        className={styles.row}
        onClick={onLogOut}
        onKeyDown={onLogOut}
        role='button'
        tabIndex={0}
      >
        <Exit />
        <Description className={styles.text}>Sign out</Description>
      </div>
    </div>
  )
}

export default ProfileDropdown
