import { RequestDataInterface, UpdatedToken } from 'interfaces/apiInterfaces'
import axios, { AxiosInstance } from 'axios'

export class NextJsApiHelper {
  private nextJsApiAxios: AxiosInstance

  constructor() {
    this.nextJsApiAxios = axios.create({
      baseURL: `${process.env.NEXT_PUBLIC_CLIENT_PORTAL_URL}/api`,
    })
  }

  async getAccess(
    user,
  ): Promise<
    RequestDataInterface<{
      isWith2FA?: boolean
      isOtpRequired?: boolean
    }>
  > {
    try {
      const res = await this.nextJsApiAxios.post('/login', {
        user: {
          email: user.email,
          password: user.password,
          code: user.code,
        },
      })

      if (res.data.error) {
        return { result: null, error: res.data.error }
      }

      return { result: res.data, error: null }
    } catch (error) {
      return { result: null, error: error?.response?.data?.error }
    }
  }

  async logOut(): Promise<void> {
    await this.nextJsApiAxios.post('/logout')
  }

  async updateAccessToken(): Promise<UpdatedToken> {
    const result = await this.nextJsApiAxios.get('/update-token')

    return result
  }
}
