import React, { useState } from 'react'
import UiLink from 'components/ui/ui-link/UiLink'
import Avatar from 'components/shared/avatar/Avatar'
import { RouterPaths } from 'constants/paths/routerPaths'
import { Body, BodySemiBold, Description } from 'components/typography'
import { User } from 'interfaces/pages-components-interfaces'
import Edit from 'images/icons/edit-icn.svg'
import Close from 'images/icons/close-icn.svg'
import BurgerIcon from 'images/icons/burger-menu-icn.svg'
import ArrowRight from 'images/icons/arrow-right-icn.svg'
import styles from './BurgerMenu.module.scss'

const BurgerMenu: React.FC<{ data: User }> = ({ data }) => {
  const [isMenuShown, setIsMenuShown] = useState(false)

  return (
    <div className={styles.container}>
      {isMenuShown ? (
        <Close onClick={(): void => setIsMenuShown(!isMenuShown)} />
      ) : (
        <BurgerIcon onClick={(): void => setIsMenuShown(!isMenuShown)} />
      )}
      {isMenuShown && (
        <div className={styles.menu}>
          <div className={styles.info}>
            <Avatar className={styles.avatar} photo={data.photo} size='big' />
            <div>
              <BodySemiBold>{data.full_name}</BodySemiBold>
              <Description>{data.position}</Description>
            </div>
            <UiLink
              href={RouterPaths.MY_PROFILE}
              onClick={(): void => setIsMenuShown(false)}
            >
              <Edit />
            </UiLink>
          </div>

          <nav>
            <ul>
              <li>
                <UiLink
                  className={styles.item}
                  href={RouterPaths.HOME}
                  onClick={(): void => setIsMenuShown(false)}
                >
                  <Body className={styles.page}>Home</Body>
                  <ArrowRight />
                </UiLink>
              </li>

              <li>
                <UiLink
                  className={styles.item}
                  href={RouterPaths.TO_DOS}
                  onClick={(): void => setIsMenuShown(false)}
                >
                  <Body className={styles.page}>To-dos</Body>
                  <ArrowRight />
                </UiLink>
              </li>

              <li>
                <UiLink
                  className={styles.item}
                  href={RouterPaths.EDUCATION}
                  onClick={(): void => setIsMenuShown(false)}
                >
                  <Body className={styles.page}>Education</Body>
                  <ArrowRight />
                </UiLink>
              </li>

              <li>
                <UiLink
                  className={styles.item}
                  href={RouterPaths.CHARTS}
                  onClick={(): void => setIsMenuShown(false)}
                >
                  <Body className={styles.page}>Charts</Body>
                  <ArrowRight />
                </UiLink>
              </li>

              <li>
                <UiLink
                  className={styles.item}
                  href={RouterPaths.FINANCIAL_PACKETS}
                  onClick={(): void => setIsMenuShown(false)}
                >
                  <Body className={styles.page}>Monthly Financials</Body>
                  <ArrowRight />
                </UiLink>
              </li>

              <li>
                <UiLink
                  className={styles.item}
                  href={RouterPaths.PARTNER_DISCOUNT}
                  onClick={(): void => setIsMenuShown(false)}
                >
                  <Body className={styles.page}>Partner Discounts</Body>
                  <ArrowRight />
                </UiLink>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  )
}

export default BurgerMenu
