import axios, { AxiosInstance } from 'axios'
import {
  ErrorDataFetchInterface,
  PlaidBankInfo,
  RequestDataInterface,
  ResetPasswordValues,
  FullUserLoginInterface,
  KeyInterface,
  ErrorDataValidationInterface,
  RequestValidationInterface,
} from 'interfaces/apiInterfaces'
import {
  CategoryInterface,
  Charts,
  EducationPageInterface,
  FinancialPacket,
  FinancialPacketsPageInterface,
  IntegrationPageInterface,
  Message,
  ToDo,
  ToDosPageInterface,
  PartnerDiscountsInterface,
  UserProfile,
  ArticlesDataInterface,
} from 'interfaces/pages-components-interfaces'
import {
  emailNotConfirmed,
  emailSuccessfullyConfirmed,
} from 'constants/emailConfirmation'
import dayjs from 'dayjs'
import * as process from 'process'
import getConfig from 'next/config'
import {
  ChartInterface,
  HomePageInterface,
  PaginationCollection,
  User,
} from '../interfaces/pages-components-interfaces'
import { NextJsApiHelper } from './nextJsApiHelper'

export class KruzeKontrolApiHelper {
  private readonly expireTime: string
  private readonly baseUrl: string
  private kruzeKontrolApiAxios: AxiosInstance
  private kruzeKontrolFormAxios: AxiosInstance
  private kruzeKontrolBlobAxios: AxiosInstance

  constructor(expireTime) {
    this.expireTime = expireTime
    this.baseUrl = `${process.env.NEXT_PUBLIC_KRUZE_CONTROL_API_URL}/api/client_portal`

    this.kruzeKontrolBlobAxios = axios.create({
      baseURL: this.baseUrl,
      responseType: 'blob',
      withCredentials: true,
    })

    this.kruzeKontrolApiAxios = axios.create({
      baseURL: this.baseUrl,
      withCredentials: true,
    })

    this.kruzeKontrolFormAxios = axios.create({
      baseURL: this.baseUrl,
      withCredentials: true,
    })
  }

  private checkIsAccessTokenExpired = (): boolean =>
    new Date() > new Date(Number(this.expireTime) * 1000)

  private updateTokenIfHeWasExpired = async (): Promise<void> => {
    if (this.checkIsAccessTokenExpired()) {
      const nextApiHelper = new NextJsApiHelper()
      await nextApiHelper.updateAccessToken()
    }
  }

  private customPOSTmethod = async <OutputType, InputType>(
    url: string,
    data?: InputType,
  ): Promise<RequestDataInterface<OutputType>> => {
    try {
      await this.updateTokenIfHeWasExpired()
      const res = await this.kruzeKontrolApiAxios.post(url, data)

      return { result: res.data as OutputType, error: null }
    } catch (error) {
      if (error.response?.status === 500) {
        return { result: null, error: error.response.status }
      }

      return {
        result: null,
        error: error.response?.data.error?.message,
        extras: error.response?.data.error.extras,
      }
    }
  }

  private customPATCHmethod = async <InputType>(
    url: string,
    data: InputType,
  ): Promise<RequestDataInterface<boolean> | ErrorDataFetchInterface> => {
    try {
      await this.updateTokenIfHeWasExpired()
      await this.kruzeKontrolFormAxios.patch(url, data)

      return { result: true }
    } catch (error) {
      if (error.response.status === 500) {
        return { result: null, error: error.response.status }
      }

      return { result: null, error: error.response.data.error.message }
    }
  }

  private customGETmethod = async <OutputType>(
    url: string,
  ): Promise<RequestDataInterface<OutputType> | ErrorDataFetchInterface> => {
    try {
      await this.updateTokenIfHeWasExpired()
      const res = await this.kruzeKontrolApiAxios.get(url)

      return { result: res.data as OutputType, error: null }
    } catch (error) {
      if (error.response.status === 500) {
        return { result: null, error: error.response.status }
      }

      return { result: null, error: error.response.data.message }
    }
  }

  private customDELETEmethod = async (
    url: string,
  ): Promise<RequestDataInterface<boolean> | ErrorDataFetchInterface> => {
    try {
      await this.updateTokenIfHeWasExpired()
      await this.kruzeKontrolApiAxios.delete(url)

      return { result: true }
    } catch (error) {
      return { result: null, error: error.response.data.error.message }
    }
  }

  // ALL GET REQUESTS

  getFinancialPageData = (): Promise<
    | RequestDataInterface<FinancialPacketsPageInterface>
    | ErrorDataFetchInterface
  > =>
    this.customGETmethod<FinancialPacketsPageInterface>(
      '/financial_bundles_page',
    )

  getMonthFinancialPacket = (
    month,
  ): Promise<
    RequestDataInterface<FinancialPacket[]> | ErrorDataFetchInterface
  > =>
    this.customGETmethod<FinancialPacket[]>(`/financial_bundles?month=${month}`)

  getProfilePage = (): Promise<
    RequestDataInterface<UserProfile> | ErrorDataFetchInterface
  > => this.customGETmethod<UserProfile>('/profile_page')

  getChartsPageData = (): Promise<
    RequestDataInterface<Charts> | ErrorDataFetchInterface
  > => this.customGETmethod<Charts>('/charts_page')

  getHomePage = (): Promise<
    RequestDataInterface<HomePageInterface> | ErrorDataFetchInterface
  > => this.customGETmethod<HomePageInterface>('/home_page')

  getIntegrationsPageData = (): Promise<
    RequestDataInterface<IntegrationPageInterface> | ErrorDataFetchInterface
  > => this.customGETmethod<IntegrationPageInterface>('/integrations_page')

  getPartnerDiscountsPage = (): Promise<
    RequestDataInterface<PartnerDiscountsInterface[]> | ErrorDataFetchInterface
  > => this.customGETmethod<PartnerDiscountsInterface[]>('/rewards_page')

  getEducationPage = (): Promise<
    RequestDataInterface<EducationPageInterface> | ErrorDataFetchInterface
  > => this.customGETmethod<EducationPageInterface>('/education_page')

  getToDosPage = (): Promise<
    RequestDataInterface<ToDosPageInterface> | ErrorDataFetchInterface
  > => this.customGETmethod<ToDosPageInterface>('/questions_page')

  getUserData = (): Promise<
    RequestDataInterface<User> | ErrorDataFetchInterface
  > => this.customGETmethod<User>('/current_user')

  getUnseenItems = (): Promise<
    RequestDataInterface<User> | ErrorDataFetchInterface
  > => this.customGETmethod<User>('/unseen_items')

  getChartCardData = (
    id: string,
    isSmallCard: boolean,
  ): Promise<RequestDataInterface<ChartInterface | ErrorDataFetchInterface>> =>
    this.customGETmethod(`/client_charts/${id}?half_year=${!!isSmallCard}`)

  getArticleCategories = (): Promise<
    RequestDataInterface<CategoryInterface[] | ErrorDataFetchInterface>
  > => this.customGETmethod(`content_categories`)

  searchArticles = (
    inputValue,
    categoryId,
    articlesPerPage,
    nextPageParams,
  ): Promise<
    RequestDataInterface<ArticlesDataInterface | ErrorDataFetchInterface>
  > =>
    this.customGETmethod(
      `education_page/search_articles?search_word=${
        inputValue || ''
      }&category_id=${categoryId || ''}&limit=${articlesPerPage || ''}${
        nextPageParams && `&next_page_params=${nextPageParams}`
      }`,
    )

  getCompletedQuestions = (
    page: number,
    startDate: Date,
    endDate: Date,
  ): Promise<
    RequestDataInterface<PaginationCollection<ToDo>> | ErrorDataFetchInterface
  > => {
    const startDateString = startDate
      ? `&start_date=${dayjs(startDate).format('YYYY-MM-DD')}`
      : ''
    const endDateString = endDate
      ? `&end_date=${dayjs(endDate).format('YYYY-MM-DD')}`
      : ''
    const urlWIthQueryParams: string = `/completed_questions?page=${page}${startDateString}${endDateString}`

    return this.customGETmethod(urlWIthQueryParams)
  }

  getOneQuestion = (
    id,
  ): Promise<RequestDataInterface<ToDo>> | ErrorDataFetchInterface =>
    this.customGETmethod(`/questions?question_id=${id}`)

  getPlaidPublicTokenForUpdate = async (
    id: number,
  ): Promise<
    RequestDataInterface<{ token: string }> | ErrorDataFetchInterface
  > =>
    this.customGETmethod(
      `/integrations_page/plaid_banks/public_token?for_update=true&item_id=${id}`,
    )

  getPlaidPublicToken = (): Promise<
    RequestDataInterface<{ token: string }> | ErrorDataFetchInterface
  > => this.customGETmethod('/integrations_page/plaid_banks/public_token')

  getAllCartsInPdf = async (): Promise<any> => {
    try {
      const res = await this.kruzeKontrolBlobAxios.get(
        '/client_charts/download_charts_pdf',
      )

      return { result: res.data, error: null }
    } catch (error) {
      return { result: null, error: error.response }
    }
  }

  getOneCartInPdf = async (id): Promise<any> => {
    try {
      const res = await this.kruzeKontrolBlobAxios.get(
        `/client_charts/${id}/download_single_chart_pdf`,
      )

      return { result: res.data, error: null }
    } catch (error) {
      return { result: null, error: error.response }
    }
  }

  getOtpSetupInfo = (): Promise<
    | RequestDataInterface<{ otp_secret_link: string; otp_secret: string }>
    | ErrorDataFetchInterface
  > => this.customGETmethod('/current_user/otp_setup_info')

  // ALL POST REQUESTS
  resetPassword = async (
    values: ResetPasswordValues,
    token: string,
  ): Promise<
    RequestValidationInterface<boolean> | ErrorDataValidationInterface
  > => {
    try {
      await axios.post(`${this.baseUrl}/users/reset_password`, {
        password: values.password,
        password_confirmation: values.confirm,
        reset_password_token: token,
      })

      return { result: true, error: null }
    } catch (error) {
      return {
        result: null,
        error: error.response?.data.error?.message,
        extras: error.response?.data.error.extras,
      }
    }
  }

  forgotPassword = async (email: {
    email: string
  }): Promise<RequestDataInterface<boolean> | ErrorDataFetchInterface> => {
    try {
      await axios.post(`${this.baseUrl}/users/forgot_password`, email)

      return { result: true, error: null }
    } catch (error) {
      return { result: null, error: error.response.data.error.message }
    }
  }

  sendInvites = async (emails: {
    emails: Array<string>
  }): Promise<RequestDataInterface<boolean> | ErrorDataFetchInterface> =>
    this.customPOSTmethod('/current_user/invite_users', emails)

  sendAnswer = async (
    answer: FormData,
  ): Promise<RequestDataInterface<boolean> | ErrorDataFetchInterface> =>
    this.customPOSTmethod('/answers', answer)

  createNewUser = async (
    formsData: FormData,
  ): Promise<
    RequestValidationInterface<boolean> | ErrorDataValidationInterface
  > => {
    try {
      const res = await this.kruzeKontrolFormAxios.post('/users', formsData)

      return { result: res.data, error: null }
    } catch (error) {
      return {
        result: null,
        error: error.response?.data.error?.message,
        extras: error.response?.data.error.extras,
      }
    }
  }

  enableOtpCode = async (otpCode: {
    code: string
  }): Promise<
    RequestDataInterface<{ backup_codes: string[] }> | ErrorDataFetchInterface
  > => this.customPOSTmethod('/current_user/enable_otp', otpCode)

  disableOtpCode = async (otpCode: {
    code: string
  }): Promise<
    RequestDataInterface<{ backup_codes: string[] }> | ErrorDataFetchInterface
  > => this.customPOSTmethod('/current_user/disable_otp', otpCode)

  resendEmailConfirmation = async (
    email: string,
  ): Promise<RequestDataInterface<boolean> | ErrorDataFetchInterface> =>
    this.customPOSTmethod(`/users/resend_email_confirmation?email=${email}`, {})

  sendPlaidData = async (
    bankInfo: PlaidBankInfo,
  ): Promise<RequestDataInterface<boolean> | ErrorDataFetchInterface> =>
    this.customPOSTmethod('/integrations_page/plaid_banks', bankInfo)

  updatePlaidData = async (
    id: string,
  ): Promise<RequestDataInterface<boolean> | ErrorDataFetchInterface> =>
    this.customPOSTmethod(`integrations_page/plaid_banks/${id}/set_updated`)

  sendTrinetId = async (
    id: string,
  ): Promise<RequestDataInterface<boolean> | ErrorDataFetchInterface> =>
    this.customPOSTmethod('/integrations_page/trinet_data', {
      trinet: { trinet_id: id },
    })

  changePassword = async (values: {
    current_password: string
    password: string
  }): Promise<
    RequestValidationInterface<boolean> | ErrorDataValidationInterface
  > => {
    try {
      const res = await this.kruzeKontrolFormAxios.post(
        '/current_user/update_password',
        {
          user: {
            current_password: values.current_password,
            password: values.password,
          },
        },
      )

      return { result: res.data, error: null }
    } catch (error) {
      return {
        result: null,
        error: error.response?.data.error?.message,
        extras: error.response?.data.error.extras,
      }
    }
  }

  createUserSession = async (
    userData: ReadableStream<Uint8Array>,
  ): Promise<
    RequestDataInterface<FullUserLoginInterface> | ErrorDataFetchInterface
  > => {
    try {
      const res = await this.kruzeKontrolApiAxios.post(
        '/auth/user_session',
        userData,
      )

      return { result: res.data, error: null }
    } catch (error) {
      return { result: null, error: error.response.data.error.message }
    }
  }

  updateAccessToken = async (
    refreshToken: string,
  ): Promise<RequestDataInterface<KeyInterface> | ErrorDataFetchInterface> => {
    try {
      const res = await this.kruzeKontrolApiAxios.post(
        '/auth/user_session/refresh',
        {
          refresh_token: refreshToken,
        },
      )

      return { result: res.data, error: null }
    } catch (error) {
      return { result: null, error: error.response?.data.error.message }
    }
  }

  emailConfirm = async (confirmationToken: string): Promise<Message> => {
    try {
      await this.kruzeKontrolApiAxios.post('/users/confirm', {
        token: confirmationToken,
      })

      return emailSuccessfullyConfirmed
    } catch (error) {
      return emailNotConfirmed
    }
  }

  // ALL PATCH REQUESTS
  updateClient = async (
    formData: FormData,
  ): Promise<RequestDataInterface<boolean> | ErrorDataFetchInterface> =>
    this.customPATCHmethod<FormData>('/client', formData)

  updateUser = async (
    formData: FormData,
  ): Promise<RequestDataInterface<boolean> | ErrorDataFetchInterface> =>
    this.customPATCHmethod<FormData>('/current_user', formData)

  // ALL DELETE REQUESTS
  deletePlaidBank = async (
    id: string,
  ): Promise<RequestDataInterface<boolean> | ErrorDataFetchInterface> =>
    this.customDELETEmethod(`/integrations_page/plaid_banks/${id}`)
}
