import React, { useEffect, useState } from 'react'
import utc from 'dayjs/plugin/utc'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react-lite'
import { authPaths } from 'constants/paths/routerPaths'
import Header from 'components/headers/header/Header'
import Error from 'next/error'
import NextNProgress from 'nextjs-progressbar'
import PageLoader from 'components/ui/page-loader/PageLoader'
import { ContextForProps } from 'interfaces/pages-components-interfaces'
import { KruzeKontrolApiHelper } from 'helpers/kruzeKontrolApiHelper'
import { ApiHelperContext } from 'components/context/ApiHelperContext'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import colors from 'styles/modules/colors.module.scss'
import dayjs from 'dayjs'
import 'styles/globals.scss'

const MyApp: any = observer(({ Component, pageProps, expireTime }) => {
  const { pathname } = useRouter()
  const apiHelperInstance = new KruzeKontrolApiHelper(expireTime)
  const isAuthPage = authPaths.includes(pathname)
  const [isLoading, setIsLoading] = useState(true)
  const [userData, setUserData] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  dayjs.extend(utc)

  const getData = async (): Promise<void> => {
    setIsLoading(true)
    const { result, error } = await apiHelperInstance.getUserData()

    if (result) {
      setUserData(result)
    }
    setErrorMessage(error)
    setIsLoading(false)
  }

  useEffect(() => {
    if (!isAuthPage) {
      getData()
    }
  }, [isAuthPage])

  return (
    <ApiHelperContext.Provider value={apiHelperInstance}>
      {isLoading && !isAuthPage ? (
        <PageLoader />
      ) : (
        <div>
          <ReactNotifications />
          {!isAuthPage && !errorMessage && <Header data={userData} />}
          <NextNProgress
            color={colors.main}
            height={4}
            startPosition={0.3}
            stopDelayMs={200}
          />
          {errorMessage && !isAuthPage ? (
            <Error statusCode={errorMessage} />
          ) : (
            <Component {...pageProps} userData={userData} />
          )}
        </div>
      )}
    </ApiHelperContext.Provider>
  )
})

MyApp.getInitialProps = async ({
  ctx,
}: {
  ctx: ContextForProps
}): Promise<{ expireTime: string }> => {
  const expireTime = ctx.req.cookies.expire_time

  return { expireTime }
}

export default MyApp
