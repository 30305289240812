export const RouterPaths = {
  HOME: '/',
  FINANCIAL_PACKETS: '/financial-packets',
  PARTNER_DISCOUNT: '/partner-discounts',
  EDUCATION: '/education',
  TO_DOS: '/to-dos',
  CHARTS: '/charts',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  RESET_PASSWORD: '/reset-password',
  FINISH_REGISTRATION: '/finish-registration',
  MY_PROFILE: '/my-profile',
  INTEGRATION: '/integration',
  LOGIN: '/api/login',
  DEEP_LINK: '/api/deep-linking/apple-deeplinks',
}

export const authPaths = [
  RouterPaths.SIGN_IN,
  RouterPaths.SIGN_UP,
  RouterPaths.RESET_PASSWORD,
  RouterPaths.LOGIN,
  RouterPaths.DEEP_LINK,
]
