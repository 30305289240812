import React, { useEffect, useRef, useState } from 'react'
import cs from 'classnames'
import { makeImageUrlWithSize } from 'helpers/makeImageUrlWithSizes'
import UserIcon from 'images/icons/profile-icn.svg'
import styles from './Avatar.module.scss'

interface Props {
  size: 'mini' | 'small' | 'middle' | 'big' | 'large'
  className?: string
  photo?: string
  initials?: string
  croppedImage?: boolean
}

const Avatar: React.FC<Props> = ({
  size,
  className,
  photo,
  initials,
  croppedImage,
}) => {
  const [blur, setBlur] = useState(true)
  const loadingImage = useRef(null)
  const [isCleaned, setIsCleaned] = useState(false)
  const sizes = {
    mini: 36,
    small: 60,
    middle: 70,
    big: 100,
    large: 320,
  }
  const imageSrc = croppedImage
    ? photo
    : makeImageUrlWithSize(photo, sizes[size], sizes[size])

  useEffect(() => {
    if (loadingImage.current?.complete) {
      setBlur(false)
    }

    if (!isCleaned) {
      loadingImage.current?.addEventListener('load', () => {
        setBlur(false)
      })
    }

    return (): void => setIsCleaned(true)
  }, [])

  return (
    <div className={cs(styles.avatar, styles[size], className)}>
      {photo && (
        <img
          alt=''
          className={blur ? styles.blur : styles.unblur}
          height={sizes[size]}
          loading='lazy'
          ref={loadingImage}
          src={imageSrc}
          width={sizes[size]}
        />
      )}
      {initials && !photo && <div className={styles.initials}>{initials}</div>}
      {!photo && !initials && <UserIcon />}
    </div>
  )
}

export default Avatar
