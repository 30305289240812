import React from 'react'
import cs from 'classnames'
import styles from './MainContainer.module.scss'

interface Props {
  children?: React.ReactNode
  className?: string
  isHomePage?: boolean
  isPageWrapper?: boolean
  ariaLabel?: string
}

const MainContainer: React.FC<Props> = ({
  children,
  className,
  isHomePage,
  isPageWrapper,
  ariaLabel,
}) => (
  <div
    aria-label={ariaLabel}
    className={cs(
      styles.container,
      className,
      isHomePage && styles.homePage,
      isPageWrapper && styles.noSpace,
    )}
  >
    {children}
  </div>
)

export default MainContainer
