import { useState, useEffect } from 'react'
import breakpoints from 'styles/modules/breakpoints.module.scss'

interface BreakpointsType {
  width: string | number
  height: string | number
  isXs: boolean
  isSm: boolean
  isMd: boolean
  isLg: boolean
  isXl: boolean
  isXxl: boolean
}

interface SizesType {
  windowSize: BreakpointsType
  isBigScreen: boolean
  isDesktop: boolean
  isSmallScreen: boolean
  isMobile: boolean
}

const { xs, sm, md, lg, xl, xxl } = breakpoints
const breakpointsOrder = [xs, sm, md, lg, xl, xxl]

const getBreakpointMax = (breakpoint: string): string => {
  const nextBreakpointIndex = breakpointsOrder.indexOf(breakpoint) + 1

  return `${parseInt(breakpointsOrder[nextBreakpointIndex], 10) - 0.02}px`
}

const getBreakpointMinMaxMediaQuery = (minBreakpointWidth: string): string =>
  `(min-width: ${minBreakpointWidth}) and (max-width: ${getBreakpointMax(
    minBreakpointWidth,
  )})`

// Hook
function useWindowSize(): SizesType {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    isXs: false,
    isSm: false,
    isMd: false,
    isLg: false,
    isXl: false,
    isXxl: false,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize(): void {
      // Set window width/height to state
      setWindowSize({
        width: innerWidth,
        height: innerHeight,
        isXs: window.matchMedia(`(max-width: ${getBreakpointMax(xs)})`).matches,
        isSm: window.matchMedia(getBreakpointMinMaxMediaQuery(sm)).matches,
        isMd: window.matchMedia(getBreakpointMinMaxMediaQuery(md)).matches,
        isLg: window.matchMedia(getBreakpointMinMaxMediaQuery(lg)).matches,
        isXl: window.matchMedia(getBreakpointMinMaxMediaQuery(xl)).matches,
        isXxl: window.matchMedia(`(min-width: ${xxl})`).matches,
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return (): void => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return {
    windowSize,
    isBigScreen: windowSize.isXxl || windowSize.isXl,
    isDesktop: windowSize.isXl || windowSize.isXxl || windowSize.isLg,
    isSmallScreen: windowSize.isSm || windowSize.isXs,
    isMobile: windowSize.isXs || windowSize.isSm || windowSize.isMd,
  }
}

export default useWindowSize
