import React, { useState, useEffect, useRef } from 'react'
import { DescriptionPoppins12 } from 'components/typography'
import { RouterPaths } from 'constants/paths/routerPaths'
import MainContainer from 'components/layout/main-container/MainContainer'
import ProfileDropdown from 'components/headers/header/profie-dropdown/ProfileDropdown'
import BurgerMenu from 'components/headers/header/burger-menu/BurgerMenu'
import { User } from 'interfaces/pages-components-interfaces'
import UiLink from 'components/ui/ui-link/UiLink'
import Avatar from 'components/shared/avatar/Avatar'
import { useRouter } from 'next/router'
import useWindowSize from 'hooks/useWindowSize'
import Logo from 'images/icons/logo-icn.svg'
import MobileLogo from 'images/icons/logo-mobile-icn.svg'
import Home from 'images/icons/home-icn.svg'
import Questions from 'images/icons/list-icn.svg'
import Charts from 'images/icons/charts-icn.svg'
import Finance from 'images/icons/finance-icn.svg'
import PartnerDiscounts from 'images/icons/partner-discounts-icn.svg'
import Bell from 'images/icons/bell-icn.svg'
import Education from 'images/icons/education-icn.svg'
import Triangle from 'images/icons/triangle-icn.svg'
import styles from './Header.module.scss'
import cs from 'classnames'

const Header: React.FC<{
  data: User
}> = ({ data }) => {
  const notifications = true
  const router = useRouter()
  const [isNotificationShown, setIsNotificationShown] = useState(false)
  const [isProfileShown, setIsProfileShown] = useState(false)
  const refNotification = useRef(null)
  const refProfile = useRef(null)
  const windowSize = useWindowSize()

  const headerItems = [
    {
      link: RouterPaths.HOME,
      img: <Home />,
      title: 'Home',
    },
    {
      link: RouterPaths.TO_DOS,
      img: <Questions />,
      title: 'To-dos',
    },
    {
      link: RouterPaths.EDUCATION,
      img: <Education />,
      title: 'Education',
    },
    {
      link: RouterPaths.CHARTS,
      img: <Charts />,
      title: 'Charts',
    },
    {
      link: RouterPaths.FINANCIAL_PACKETS,
      img: <Finance />,
      title: 'Monthly Financials',
    },
    {
      link: RouterPaths.PARTNER_DISCOUNT,
      img: <PartnerDiscounts />,
      title: 'Partner Discounts',
    },
  ]

  const dropdownsHandler = (event: Event, dropdownName: string): void => {
    if (dropdownName === 'notifications') {
      setIsNotificationShown(!isNotificationShown)
    } else if (
      refNotification.current &&
      !refNotification.current.contains(event.target)
    ) {
      setIsNotificationShown(false)
    }

    if (dropdownName === 'profile') {
      setIsProfileShown(!isProfileShown)
    } else if (
      refProfile.current &&
      !refProfile.current.contains(event.target)
    ) {
      setIsProfileShown(false)
    }
  }

  useEffect(() => {
    // @ts-ignore
    document.addEventListener('click', dropdownsHandler, true)

    return (): void => {
      // @ts-ignore
      document.removeEventListener('click', dropdownsHandler, true)
    }
  })

  return (
    <div className={styles.wrapper}>
      <MainContainer>
        <div className={styles.container}>
          <div className={styles.logoWrapper}>
            <BurgerMenu data={data} />
            <UiLink
              ariaLabel='Go to the Home page'
              className={styles.logoLink}
              href='/'
            >
              {windowSize.isMobile ? <MobileLogo /> : <Logo />}
            </UiLink>
          </div>
          <nav className={styles.menu}>
            <ul>
              {headerItems.map((el) => (
                <li
                  className={cs(
                    router.pathname === el.link && styles.activePage,
                  )}
                  key={el.link}
                >
                  <UiLink href={el.link}>
                    <div
                      aria-label='header-page-icon'
                      className={styles.imgWrapper}
                    >
                      {el.title === 'To-dos' && data.open_questions_count > 0 && (
                        <div
                          aria-label='counter-badge-questions'
                          className={styles.badge}
                        >
                          {data.open_questions_count}
                        </div>
                      )}
                      {el.title === 'Monthly Financials' &&
                        data.unseen_financial_bundles_count > 0 && (
                          <div
                            aria-label='counter-badge-bundles'
                            className={styles.badge}
                          >
                            {data.unseen_financial_bundles_count}
                          </div>
                        )}
                      {el.img}
                    </div>
                    <DescriptionPoppins12 className={styles.namePage}>
                      {el.title}
                    </DescriptionPoppins12>
                  </UiLink>
                </li>
              ))}
            </ul>
          </nav>
          <div className={styles.options}>
            <div className={styles.bell} ref={refNotification}>
              <Bell
                onClick={(): void => dropdownsHandler(event, 'notifications')}
              />
              {notifications && <div className={styles.notifications} />}
            </div>
            <UiLink
              ariaLabel='Go to the Profile page'
              href={RouterPaths.MY_PROFILE}
            >
              <Avatar
                className={styles.avatar}
                photo={data.photo}
                size='small'
              />
            </UiLink>
            <div
              aria-label='show dropdown'
              className={styles.btn}
              onClick={(): void => dropdownsHandler(event, 'profile')}
              onKeyDown={(): void => dropdownsHandler(event, 'profile')}
              ref={refProfile}
              role='button'
              tabIndex={0}
            >
              <Triangle className={cs({ [styles.up]: isProfileShown })} />
              {isProfileShown && <ProfileDropdown />}
            </div>
          </div>
        </div>
      </MainContainer>
    </div>
  )
}

export default Header
