import styles from './typography.module.scss'
import React, {
  FunctionComponent,
  ReactComponentElement,
  ReactNode,
} from 'react'
import cs from 'classnames'

interface Props {
  children: ReactNode
  as?: React.ComponentType<{ [key: string]: ReactNode }> | string
  className?: string
  [key: string]: ReactNode
}

const typographyElement = (
  primaryClassName: string,
  defaultTag?: string,
): FunctionComponent<Props> => ({
  children,
  as: Component = defaultTag || 'p',
  className,
  ...props
}): ReactComponentElement<any> => (
  <Component className={cs(primaryClassName, className)} {...props}>
    {children}
  </Component>
)

export const Headline1 = typographyElement(styles.Headline1, 'h1')
export const Headline1blue = typographyElement(styles.Headline1blue, 'h1')
export const Headline1white = typographyElement(styles.Headline1white, 'h1')
export const Headline2 = typographyElement(styles.Headline2, 'h2')
export const Headline3 = typographyElement(styles.Headline3, 'h3')
export const Headline3blue = typographyElement(styles.Headline3blue, 'h3')
export const Headline4 = typographyElement(styles.Headline4, 'h4')
export const Headline4medium = typographyElement(styles.Headline4medium, 'h4')
export const Headline4Bold = typographyElement(styles.Headline4Bold, 'h4')
export const Subheading = typographyElement(styles.Subheading)
export const Body = typographyElement(styles.Body)
export const BodySemiBold = typographyElement(styles.BodySemiBold)
export const Description = typographyElement(styles.Description)
export const DescriptionLato12 = typographyElement(styles.DescriptionLato12)
export const DescriptionPoppins12 = typographyElement(
  styles.DescriptionPoppins12,
)
export const DescriptionPoppins12Bold = typographyElement(
  styles.DescriptionPoppins12Bold,
)
export const DescriptionPoppins10 = typographyElement(
  styles.DescriptionPoppins10,
)
export const ButtonFont = typographyElement(styles.ButtonFont)
export const LinkFont = typographyElement(styles.LinkFont)
