export const emailSuccessfullyConfirmed = {
  type: 'success',
  title: 'Your email has been successfully confirmed',
  text: 'Sign in to go to Client Portal.',
}

export const emailNotConfirmed = {
  type: 'error',
  title: 'Your email not confirmed',
  text: 'Invalid token',
}
