import React from 'react'
import MainContainer from 'components/layout/main-container/MainContainer'
import Logo from 'images/icons/huge-logo.svg'
import styles from './PageLoader.module.scss'

const PageLoader: React.FC = () => (
  <MainContainer className={styles.wrapper}>
    <Logo />
  </MainContainer>
)

export default PageLoader
